import React from "react"

export default props => (
  <div className="px-6 lg:px-0 w-auto container mx-auto m-32 mb-12">
    <h1 className="text-white mt-4 font-sans tracking-wide font-bold">
      {props.title}
    </h1>
    <p className="mt-4 text-gray-400">{props.description}</p>
  </div>
)
