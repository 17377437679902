import React from "react"
import classNames from "classnames"

export default props => {
  const color = props.color || "gray"
  const textIntensity = props.textIntensity || 800
  const backgroundIntensity = props.backgroundIntensity || 100

  return (
    <span
      className={classNames([
        "mr-2 inline-block mt-2 text-xs px-4 pt-2 pb-1 rounded-full text-xs uppercase font-semibold tracking-wide",
        "text-" + color + "-" + textIntensity,
        "bg-" + color + "-" + backgroundIntensity,
        ...(props.className || []),
      ])}
    >
      {props.text}
    </span>
  )
}
