import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "./layout"
import HeroText from "./hero-text"
import { GatsbySeo, BlogJsonLd } from "gatsby-plugin-next-seo"
import Image from "gatsby-image"
import Pill from "@components/pill"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1
        ? "/blog/"
        : "/blog/page-" + (currentPage - 1).toString()
    const nextPage = "/blog/page-" + (currentPage + 1).toString()
    const showPaginationNumbers = false
    const postsResume = data.allMarkdownRemark.edges.map(edge => {
      return {
        headline: edge.node.frontmatter.title,
        image: edge.node.frontmatter.image.childImageSharp.fluid.src,
      }
    })

    const Posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date) // You can filter your blog based on some criteria
      .map(edge => (
        <div className="mb-12">
          {/*<p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase mb-2 text-xs">*/}
          {/*  {edge.node.frontmatter.date}*/}
          {/*</p>*/}
          <Link key={edge.node.id} to={edge.node.frontmatter.path}>
            <h1 className="leading-tight">{edge.node.frontmatter.title}</h1>
          </Link>
          <p className="text-base leading-6 text-gray-500 tracking-wide mt-2">
            <span>{edge.node.frontmatter.date}</span>
            <span> ⏤ </span>
            <span>{edge.node.fields.readingTime.text}</span>
            <span> ⏤ </span>
            {edge.node.frontmatter.categories &&
              edge.node.frontmatter.categories.map(category => {
                return (
                  <span>
                    <a
                      className="text-gray-500 "
                      href={"/blog/category/" + category}
                    >
                      {category}
                    </a>
                    ,{" "}
                  </span>
                )
              })}
          </p>
          {edge.node.frontmatter.image && (
            <Link key={edge.node.id} to={edge.node.frontmatter.path}>
              <Image
                fluid={edge.node.frontmatter.image.childImageSharp.fluid}
                className="my-4 mx-auto bg-gray-100 hover:opacity-75 transition"
              />
            </Link>
          )}
          <div className="mt-6">{edge.node.excerpt}</div>
          <div className="pattern-dots-md bg-transparent text-gray-400 h-7 mt-12 mx-auto max-w-xs"></div>
        </div>
      ))

    const renderPagination = () => {
      const buttonClass =
        "p-10 block bg-gray-100 hover:bg-blue-500 hover:text-white transition duration-300 hover:scale-110 transform rounded hover:shadow-2xl text-gray-900 hover:no-underline font-semibold"
      return (
        <>
          <GatsbySeo title="Blog" />
          <BlogJsonLd
            url="https://thefunbots.com/blog"
            headline="thefunbots blog & news"
            //images="https://example.com/photos/1x1/photo.jpg"
            posts={postsResume}
            // datePublished="2015-02-05T08:00:00+08:00"
            // dateModified="2015-02-05T09:00:00+08:00"
            // authorName="Jane Blogs"
            // description="This is a mighty good description of this blog."
          />
          <div className="container grid grid-cols-2 gap-4">
            <div className="text-center">
              {!isFirst && (
                <Link to={prevPage} rel="prev" className={buttonClass}>
                  ← Previous Page
                </Link>
              )}
            </div>
            {/*{Array.from({ length: numPages }, (_, i) => (*/}
            {/*  <li*/}
            {/*    key={`pagination-number${i + 1}`}*/}
            {/*    style={{*/}
            {/*      margin: 0,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Link*/}
            {/*      to={`/${i === 0 ? "" : i + 1}`}*/}
            {/*      style={{*/}
            {/*        textDecoration: "none",*/}
            {/*        color: i + 1 === currentPage ? "#ffffff" : "",*/}
            {/*        background: i + 1 === currentPage ? "#007acc" : "",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {i + 1}*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*))}*/}
            <div className="text-center">
              {!isLast && (
                <Link to={nextPage} rel="next" className={buttonClass}>
                  Next Page →
                </Link>
              )}
            </div>
          </div>
        </>
      )
    }

    return (
      <Layout>
        <GatsbySeo title="Blog" />
        <HeroText
          title="Blog & News"
          description="The latest cool thing are here and this is large yeah cool want more yes"
        />
        <div className="py-12 bg-white h-full text-black">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
            {Posts}
          </div>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
            {renderPagination()}
          </div>
        </div>
        {/*<div className="container mx-auto">{Posts}</div>*/}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
      filter: { fields: { draft: { eq: false } } }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image {
              childImageSharp {
                fluid {
                  src
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
            categories
            tags
            author {
              name
              avatar
            }
          }
        }
      }
    }
  }
`
